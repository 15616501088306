<template>
  <div>
    <MySubscriptionInformation v-if="getActivePlan.status" />
    <PackageList />
  </div>
</template>

<script>
import Modules from "../../mixins/Modules";
import PackageList from "./List.vue";
import MySubscriptionInformation from "../MySubscription/Information.vue";
export default {
  mixins: [Modules],
  components: {
    PackageList,
    MySubscriptionInformation,
  },
};
</script>

<style></style>
