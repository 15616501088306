<template>
  <div>
    <Packages />
  </div>
</template>

<script>
import Packages from "../components/Packages/index.vue";
export default {
  components: {
    Packages,
  },
};
</script>

<style></style>
